import React from 'react';
import infoIcon from '../assets/infomation.png';

const InfoBox = () => {
  return (
    <div style={styles.container}>
      <p style={styles.text}>
      When you click on a link, your own links will be offered to other members of the ClickFair network to be clicked.
      </p>
      <img src={infoIcon} alt="Informação" style={styles.icon} />
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    padding: '20px',
    borderRadius: '15px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    width: '80%',
    margin: '20px auto',
    overflow: 'hidden',
    textAlign: 'center',
  },
  text: {
    fontSize: '16px',
    color: '#FFFFFF',
    lineHeight: '1.5',
    zIndex: 2,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: '10%',
    width: '120px',
    height: '120px',
    transform: 'translate(50%, -50%)',
    opacity: 0.3,  // Aumentando a opacidade para tornar o ícone mais visível
    zIndex: 1,
  }
};

export default InfoBox;
