import React, { useState } from 'react';
import { postLink } from '../api'; // Importe a função postLink

const LinkInput = () => {
  const [link, setLink] = useState('');
  const [description, setDescription] = useState(''); // Novo estado para a descrição
  const [hover, setHover] = useState(false);

  const handleAddLink = async () => {
    if (link.trim() && description.trim()) {
      const userId = localStorage.getItem('userID');
      
      // Verifica se o ID do usuário está presente no localStorage
      if (!userId) {
        alert("User ID not found. Please log in.");
        console.error("User ID not found. Please log in.");
        return;
      }

      try {
        await postLink(link, description, userId);
        setLink('');
        setDescription(''); // Limpa a descrição após adicionar o link
        alert("Link registered successfully!");
      } catch (error) {
        // Verifica se o erro é que o link já foi registrado
        if (error.message.includes("Link already registered")) {
          alert("Error: This link is already registered.");
        } else {
          console.error("Error adding link:", error.message);
        }
      }
    } else {
      alert("Please provide both a link and a description.");
    }
  };

  return (
    <div style={styles.container}>
      <input
        style={styles.input}
        type="text"
        placeholder="Add your link"
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <input
        style={styles.input}
        type="text"
        placeholder="Add a description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <button
        style={{ ...styles.button, ...(hover ? styles.buttonHover : {}) }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleAddLink}
      >
        Add Link
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
    width: '80%',
  },
  input: {
    width: '100%',
    padding: '12px 20px',
    fontSize: '16px',
    borderRadius: '25px',
    border: 'none',
    outline: 'none',
    marginBottom: '15px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(5px)',
    color: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
  },
  button: {
    width: '40%',
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '25px',
    border: 'none',
    backgroundColor: '#FF6F61',
    color: '#fff',
    cursor: 'pointer',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#FF4C4C',
    transform: 'translateY(-2px)',
  },
};

export default LinkInput;
