import React, { useState } from 'react';
import Modal from 'react-modal';
import { QRCodeCanvas } from 'qrcode.react';
import Lottie from 'lottie-react';
import successAnimation from '../assets/Animation_check.json';
import errorAnimation from '../assets/Animation_falha.json';

// Configure o estilo dos modais
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(26, 30, 93, 0.9)',
    borderRadius: '20px',
    padding: '30px',
    width: '80%',
    maxWidth: '400px',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    color: 'white',
  },
};

const alertModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(26, 30, 93, 0.9)',
    borderRadius: '20px',
    padding: '30px',
    width: '50%',
    maxWidth: '400px',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.5)',
    color: 'white',
    textAlign: 'center',
  },
};

const DonateModal = ({ isOpen, onRequestClose }) => {
  const [currency, setCurrency] = useState('USDT'); // Moeda padrão USDT
  const [address, setAddress] = useState('0xf3967746b726ebd746ef7f8966219bf83b3dd43e');
  const [memo, setMemo] = useState('');
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertAnimation, setAlertAnimation] = useState(successAnimation);

  const handleCurrencyChange = (e) => {
    const selectedCurrency = e.target.value;
    setCurrency(selectedCurrency);

    if (selectedCurrency === 'TON') {
      setAddress('EQD5mxRgCuRNLxKxeOjG6r14iSroLF5FtomPnet-sgP5xNJb');
      setMemo('107521698');
    } else {
      setAddress('0xf3967746b726ebd746ef7f8966219bf83b3dd43e');
      setMemo('');
    }
  };

  const copyAddress = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(address)
        .then(() => {
          alert('Address copied to clipboard!');
        })
        .catch((err) => {
          alert('Failed to copy: ', err);
        });
    } else {
      const fallbackInput = document.createElement('input');
      fallbackInput.value = address;
      document.body.appendChild(fallbackInput);
      fallbackInput.select();
      fallbackInput.setSelectionRange(0, 99999); // For mobile devices
      document.execCommand('copy');
      document.body.removeChild(fallbackInput);
      alert('Address copied to clipboard!');
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles} contentLabel="Donate Modal">
        <h2 style={styles.title}>Support ClickFair</h2>
        <p style={styles.description}>
          Your donation helps us grow and improve. Choose your favorite cryptocurrency, scan the QR code, and make a direct impact today. Every contribution fuels innovation and keeps the platform ad-free. Thank you for supporting us!
        </p>

        <select style={styles.select} value={currency} onChange={handleCurrencyChange}>
          <option value="USDT">Tether (USDT)</option>
          <option value="USDC">USD Coin (USDC)</option>
          <option value="BTC">Bitcoin (BTC)</option>
          <option value="ETH">Ethereum (ETH)</option>
          <option value="TON">Toncoin (TON)</option>
          <option value="BNB">BNB (BEP20)</option>
          <option value="SHIBA">Shiba Inu (SHIBA)</option>
          <option value="LTC">Litecoin (LTC)</option>
          <option value="SOL">Solana (SOL)</option>
        </select>

        <div style={styles.qrCodeContainer}>
          <QRCodeCanvas value={address} size={180} bgColor="#ffffff" fgColor="#000000" />
        </div>

        <div style={styles.infoContainer}>
          <p style={styles.infoText}><strong>Address:</strong> {address}</p>
          {memo && <p style={styles.infoText}><strong>Memo:</strong> {memo}</p>}
        </div>

        <div style={styles.buttonContainer}>
          <button type="button" style={styles.button} onClick={copyAddress}>
            Copy Address
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={alertModalOpen}
        onRequestClose={onRequestClose}
        style={alertModalStyles}
        contentLabel="Alert Modal"
      >
        <Lottie animationData={alertAnimation} loop={false} style={styles.alertAnimation} />
        <p style={styles.alertText}>{alertMessage}</p>
      </Modal>
    </>
  );
};

const styles = {
  title: {
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
    color: '#FFD700',
  },
  description: {
    marginBottom: '20px',
    fontSize: '16px',
    textAlign: 'center',
    color: '#fff',
  },
  select: {
    padding: '12px',
    marginBottom: '15px',
    borderRadius: '12px',
    border: 'none',
    outline: 'none',
    fontSize: '16px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: '#fff',
    backdropFilter: 'blur(5px)',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  qrCodeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    padding: '10px',  // Reduced padding
    width: '100%',     // Reduced width to make it narrower
    borderRadius: '12px',
    backgroundColor: '#fff',
    
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  },
  infoContainer: {
    textAlign: 'center',
  },
  infoText: {
    fontSize: '14px',
    color: '#fff',
    marginBottom: '5px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    padding: '12px 20px',
    borderRadius: '12px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#FF6F61',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  },
  alertAnimation: {
    width: 150,
    height: 150,
    marginBottom: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  alertText: {
    fontSize: '18px',
    color: '#fff',
    textAlign: 'center',
  },
};

export default DonateModal;
