import React, { useState } from 'react';
import Modal from 'react-modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { loginUser } from '../api';
import Lottie from 'lottie-react';
import animationData from '../assets/animation_wait.json';

const environment = process.env.NODE_ENV || 'development';
const SITE_KEY = environment === 'development'
  ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'  // Chave de teste do Google
  : '6Le33TIqAAAAAAY_w1CTBSnj9HH3oUv8s9RvWloy'; // Chave real para produção

const LoginModal = ({ isOpen, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    if (!email || !recaptchaToken) {
      alert("Please complete all fields.");
      return;
    }

    setLoading(true);

    try {
      const loginResponse = await loginUser(email);

      if (loginResponse.success) {
        window.location.reload(); // Recarrega a página após login bem-sucedido
      } else {
        alert(loginResponse.error || "Login failed.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Login Modal"
    >
      <h2 style={styles.title}>Login</h2>
      <form style={styles.form}>
        <input
          style={styles.input}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div style={styles.recaptchaContainer}>
          <ReCAPTCHA
            sitekey={SITE_KEY}
            onChange={(token) => setRecaptchaToken(token)}
          />
        </div>
        <div style={styles.buttonContainer}>
          <button
            type="button"
            style={styles.button}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? (
              <Lottie animationData={animationData} loop={true} style={styles.lottieAnimation} />
            ) : (
              'Login'
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#1a1e5d',
    borderRadius: '20px',
    padding: '30px',
    color: 'white',
    width: '400px',
    maxWidth: '80%',
    textAlign: 'center',
  },
};

const styles = {
  title: {
    marginBottom: '20px',
    fontSize: '24px',
    color: '#FFD700',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '12px',
    marginBottom: '20px',
    borderRadius: '8px',
    border: 'none',
    outline: 'none',
    fontSize: '16px',
  },
  recaptchaContainer: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    padding: '12px 20px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#FF6F61',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    width: '100%',
  },
  lottieAnimation: {
    width: '40px',
    height: '40px',
    margin: 'auto',
  },
};

export default LoginModal;
