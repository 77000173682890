import React, { useEffect, useState } from 'react';
import linkIcon from '../assets/link.png'; // Ensure the path to the icon is correct
import { fetchLinks } from '../api'; // Import the function to fetch links

const LinksList = () => {
  const [links, setLinks] = useState([]);

  // Function to fetch links
  const getLinks = async () => {
    const fetchedLinks = await fetchLinks();
    setLinks(fetchedLinks);
  };

  // Load links when the component mounts
  useEffect(() => {
    getLinks();
  }, []);

  // Function to handle link click
  const handleLinkClick = async (linkId, url) => {
    const user_id = localStorage.getItem('userID');

    if (!user_id) {
      console.error('User ID is missing in localStorage.');
      return;
    }

    try {
      const response = await fetch('https://clickfair.io/api/register_click.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id,
          link_id: linkId
        })
      });

      const data = await response.json();

      if (data.success) {
      //  console.log('Click registered successfully:', data.message);
        // Refresh links after a successful click
        getLinks();
        // Redirect to the URL after the click is registered
        window.open(url, '_blank');
      } else {
        console.error('Error registering click:', data.message);
      }
    } catch (error) {
      console.error('Request error:', error);
    }
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Links seeking clicks</h3>
      <ul style={styles.list}>
        {links.length > 0 ? (
          links.map((link, index) => (
            <li 
              key={index} 
              style={styles.listItem}
              onClick={() => handleLinkClick(link.link_id, link.url)} // Call the function on the entire list item click
            >
              <img src={linkIcon} alt="Link Icon" style={styles.icon} />
              <div style={styles.textContainer}>
                <span style={styles.name}>{link.description ? link.description : link.name}</span>
                <span style={styles.link}>
                  {link.url.length > 40 ? `${link.url.slice(0, 40)}...` : link.url}
                </span>
              </div>
            </li>
          ))
        ) : (
          <p style={styles.noLinks}>No links available at the moment.</p>
        )}
      </ul>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    padding: '5px',
    borderRadius: '15px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    width: '90%',
    margin: '20px auto',
  },
  title: {
    fontSize: '18px',
    color: '#FFFFFF',
    marginBottom: '10px',
    textAlign: 'center',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '10px 0',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    cursor: 'pointer', // Add pointer cursor to indicate it's clickable
   // backgroundColor: 'rgba(255, 255, 255, 0.05)', // Add background to highlight the item
    padding: '15px',
    borderRadius: '10px',
    transition: 'background-color 0.3s ease',
  },
  listItemHover: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Hover effect
  },
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
    marginTop: '5px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: '16px',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  link: {
    color: '#FFFFFF',
    textDecoration: 'none',
    fontSize: '14px',
    maxWidth: '250px', // Limit the link size
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Add "..." at the end of long links
    whiteSpace: 'nowrap',
  },
  noLinks: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '16px',
    marginTop: '20px',
  }
};

export default LinksList;
