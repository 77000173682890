import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { registerUser, generateRandomHash, generateToken } from '../api';
import animationData from '../assets/animation_wait.json';
import successAnimation from '../assets/Animation_check.json';
import errorAnimation from '../assets/Animation_falha.json';

// Configure o estilo dos modais
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(26, 30, 93, 0.9)',
    borderRadius: '20px',
    padding: '30px',
    width: '70%',
    maxWidth: '70%',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    color: 'white',
  },
};

const alertModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(26, 30, 93, 0.9)',
    borderRadius: '20px',
    padding: '30px',
    width: '50%',
    maxWidth: '400px',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.5)',
    color: 'white',
    textAlign: 'center',
  },
};

const lottieStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0',
  },
};

Modal.setAppElement('#root');

const environment = process.env.NODE_ENV || 'development';

const SITE_KEY = environment === 'development'
  ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  : '6Le33TIqAAAAAAY_w1CTBSnj9HH3oUv8s9RvWloy';

const RegisterModal = ({ isOpen, onRequestClose }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertAnimation, setAlertAnimation] = useState(successAnimation);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [isOpen]);

  const handleRegister = async () => {
    if (!recaptchaToken) {
      setAlertMessage('Please complete the reCAPTCHA.');
      setAlertAnimation(errorAnimation);
      setAlertModalOpen(true);
      return;
    }

    const hash = generateRandomHash();
    const token = generateToken(hash);

    const userData = {
      fullName,
      email,
      token,
      hash,
      recaptchaToken,
    };
    console.log('Submitted data:', userData);
    try {
      const response = await registerUser(userData);

      if (response.success) {
        setAlertMessage('Registration successful!');
        setAlertAnimation(successAnimation);
      } else {
        setAlertMessage(response.error || 'Registration error.');
        setAlertAnimation(errorAnimation);
      }
      setAlertModalOpen(true);
    } catch (error) {
      setAlertMessage('Error connecting to the server.');
      setAlertAnimation(errorAnimation);
      setAlertModalOpen(true);
      console.error('Error during registration:', error);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleClose = () => {
    setLoading(true);
    onRequestClose();
    setAlertModalOpen(false);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      <Modal
        isOpen={loading && isOpen}
        style={lottieStyles}
        contentLabel="Loading Modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <div style={styles.loadingContainer}>
          <div style={styles.lottieBox}>
            <Lottie animationData={animationData} loop={true} style={styles.lottieAnimation} />
            <p style={styles.loadingText}>Please wait...</p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpen && !loading}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Register Modal"
      >
        <h2 style={styles.title}>Register</h2>
        <form style={styles.form}>
          <input
            style={styles.input}
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <input
            style={styles.input}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div style={styles.recaptchaContainer}>
            <ReCAPTCHA
              sitekey={SITE_KEY}
              onChange={handleRecaptchaChange}
            />
          </div>
          <div style={styles.buttonContainer}>
            <button
              type="button"
              style={{ ...styles.button, ...(isHovering ? styles.buttonHover : {}) }}
              onClick={handleRegister}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Register
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={alertModalOpen}
        onRequestClose={handleClose}
        style={alertModalStyles}
        contentLabel="Alert Modal"
      >
        <Lottie animationData={alertAnimation} loop={false} style={styles.alertAnimation} />
        <p style={styles.alertText}>{alertMessage}</p>
      </Modal>
    </>
  );
};

const styles = {
  title: {
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
    color: '#FFD700',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '12px',
    marginBottom: '15px',
    borderRadius: '12px',
    border: 'none',
    outline: 'none',
    fontSize: '16px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: '#fff',
    backdropFilter: 'blur(5px)',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  recaptchaContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    padding: '12px 20px',
    borderRadius: '12px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#FF6F61',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#FF4C4C',
    transform: 'translateY(-2px)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  lottieBox: {
    backgroundColor: '#fff',
    borderRadius: '15px',
    padding: '20px',
    width: '200px',
    height: '200px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottieAnimation: {
    width: 100,
    height: 100,
  },
  loadingText: {
    marginTop: '10px',
    fontSize: '16px',
    color: '#333',
  },
  alertAnimation: {
    width: 150,
    height: 150,
    marginBottom: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  alertText: {
    fontSize: '18px',
    color: '#fff',
    textAlign: 'center',
  },
};

export default RegisterModal;
