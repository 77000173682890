// src/App.js
import React, { useState } from 'react';
import Header from './components/Header';
import LinkInput from './components/LinkInput';
import InfoBox from './components/InfoBox';
import Buttons from './components/Buttons';
import LinksList from './components/LinksList';
import pt from './locales/pt.json';
import en from './locales/en.json';
import './App.css';  // Para estilos globais, se necessário

function App() {
  const [language, setLanguage] = useState('pt');  // Estado para gerenciar o idioma selecionado
  const texts = language === 'pt' ? pt : en;  // Seleção do conjunto de textos com base no idioma

  const toggleLanguage = () => {
    setLanguage(prevLang => (prevLang === 'pt' ? 'en' : 'pt'));  // Alterna entre português e inglês
  };

  return (
    <div style={styles.app}>
      {/* Passa o idioma e a função de alternar idioma como props para o Header */}
      <Header texts={texts.header} toggleLanguage={toggleLanguage} />
      <LinkInput texts={texts.linkInput} />
      <InfoBox texts={texts.infoBox} />
      <Buttons texts={texts.buttons} />
      <LinksList texts={texts.linksList} />
    </div>
  );
}

const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#1a1e5d',
    color: 'white',
    padding: '20px',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};

export default App;
