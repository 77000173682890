import sha256 from 'crypto-js/sha256';

// Função para gerar um hash aleatório
export const generateRandomHash = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let hash = '';
  for (let i = 0; i < 15; i++) {
    hash += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return hash;
};

// Chave para gerar token
export const getChave = () => {
  return "a51a03151546f48f586b0f96f5e6a38bfcce9854adac68d28742eb0de8527cda";
};

// Função para gerar o token de segurança
export const generateToken = (hash) => {
  const chave = getChave();
  return sha256(hash + chave).toString();
};

// Função para registrar o usuário
export const registerUser = async (userData) => {
  try {
    const response = await fetch('https://clickfair.io/api/register.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || 'Error during registration');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Função para logar o usuário
export const loginUser = async (email) => {
  const hash = generateRandomHash();
  const token = generateToken(hash);

  const loginData = {
    email,
    token,
    hash,
  };

  try {
    const response = await fetch('https://clickfair.io/api/login_user.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    });

    const data = await response.json();

    console.log(data);

    if (response.ok && data.success) {
      localStorage.setItem('userName', data.name);  // Armazena o nome do usuário
      localStorage.setItem('userID', data.userID);  // Armazena o ID do usuário
      localStorage.setItem('userToken', token);     // Armazena o token gerado
      return data;
    } else {
      throw new Error(data.message || 'Error during login');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Função para enviar o link para o servidor
export const postLink = async (link, description, userId) => {
  const hash = generateRandomHash();
  const token = generateToken(hash);

  const linkData = {
    url: link,
    description,
    token,
    hash,
    user_id: userId,
  };

  try {
    const response = await fetch('https://clickfair.io/api/post_link.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(linkData),
    });

    const data = await response.json();

    if (response.ok && data.success) {
   //   console.log("Link registered successfully:", data.message);
      return data;
    } else {
      console.error("Error registering link:", data.message);
      throw new Error(data.message || 'Error registering link');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Função para verificar se o usuário está autenticado
export const isAuthenticated = () => {
  const token = localStorage.getItem('userToken');
  return !!token;
};



export const fetchLinks = async () => {
  const user_id = localStorage.getItem('userID'); // Obtém o user_id do localStorage

  // Verifique se user_id está disponível
  if (!user_id) {
    console.error('ID de usuário ausente no localStorage.');
    return [];
  }

  // Gerar um novo hash aleatório
  const hash = generateRandomHash();

  // Gerar o token de verificação usando o hash
  const tokenWithHash = generateToken(hash);

//  console.log('Enviando user_id, tokenWithHash, e hash:', { user_id, tokenWithHash, hash });

  try {
    const response = await fetch('https://clickfair.io/api/get_links.php', {
      method: 'POST', // Usando POST conforme esperado pelo servidor
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id,
        token: tokenWithHash, // Envia o token gerado a partir do hash
        hash // Envia o hash
      }),
    });

  //  console.log('Resposta bruta do servidor:', response);

    const data = await response.json();

  //  console.log('Dados convertidos em JSON:', data);

    if (response.ok && data.success) {
      return data.links || [];
    } else {
      console.error('Erro ao buscar links:', data.message);
      return [];
    }
  } catch (error) {
    console.error('Erro na requisição:', error);
    return [];
  }
};


export const getUserStats = async (userId) => {
    const hash = generateRandomHash(); // Use a função que você já tem para gerar o hash
    const token = generateToken(hash); // Gere o token com base no hash
  
    const statsData = {
      user_id: userId,
      token,
      hash
    };
  
    try {
      const response = await fetch('https://clickfair.io/api/get_user_stats.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(statsData),
      });
  
      const data = await response.json();
  
      if (response.ok && data.success) {
        return data; // Retorna os dados de links e cliques
      } else {
        throw new Error(data.message || 'Erro ao buscar estatísticas do usuário');
      }
    } catch (error) {
      console.error('Erro:', error);
      throw error;
    }
  };
  

  export const sendDonationData = async (userId, amount, currency) => {
    try {
      const response = await fetch('https://clickfair.io/api/payment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          amount: amount,
          currency: currency,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok && data.success) {
        console.log('Donation registered successfully:', data.message);
        return data;
      } else {
        console.error('Error registering donation:', data.message);
      }
    } catch (error) {
      console.error('Error sending donation data:', error);
    }
  };
  