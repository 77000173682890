import React, { useState } from 'react';
import RegisterModal from './RegisterModal';
import DonateModal from './DonateModal'; // Import DonateModal

const ActionButtons = () => {
  const [hovered, setHovered] = useState(null);
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const [donateModalIsOpen, setDonateModalIsOpen] = useState(false);

  const handleMouseEnter = (button) => {
    setHovered(button);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const openRegisterModal = () => {
    setRegisterModalIsOpen(true);
  };

  const closeRegisterModal = () => {
    setRegisterModalIsOpen(false);
  };

  const openDonateModal = () => {
    setDonateModalIsOpen(true);
  };

  const closeDonateModal = () => {
    setDonateModalIsOpen(false);
  };

  return (
    <div style={styles.container}>
      <button
        style={{ ...styles.button, ...(hovered === 'donate' ? styles.buttonHover : {}) }}
        onMouseEnter={() => handleMouseEnter('donate')}
        onMouseLeave={handleMouseLeave}
        onClick={openDonateModal} // Abre o modal de doação
      >
        Donate
      </button>
      <button
        style={{ ...styles.button, ...(hovered === 'register' ? styles.buttonHover : {}) }}
        onMouseEnter={() => handleMouseEnter('register')}
        onMouseLeave={handleMouseLeave}
        onClick={openRegisterModal} // Abre o modal de registro
      >
        Register
      </button>

      <RegisterModal isOpen={registerModalIsOpen} onRequestClose={closeRegisterModal} />
      <DonateModal isOpen={donateModalIsOpen} onRequestClose={closeDonateModal} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '20px',
  },
  button: {
    width: '150px',
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '25px',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
    background: 'linear-gradient(45deg, #FFA500, #FF4500)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    textAlign: 'center',
  },
  buttonHover: {
    transform: 'translateY(-2px)',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
  },
};

export default ActionButtons;
