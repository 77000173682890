import React, { useState, useEffect } from 'react';
import myNetworkIcon from '../assets/mynetwork.png';
import myClicksIcon from '../assets/myclicks.png';
import tradeFairIcon from '../assets/trade-fair.png';
import profileIcon from '../assets/profile.png';
import LoginModal from './LoginModal';
import { isAuthenticated, getUserStats } from '../api';

const Header = () => {
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userID, setUserID] = useState('');
  const [userLinks, setUserLinks] = useState(0); // Estado para armazenar o total de links
  const [userClicks, setUserClicks] = useState(0); // Estado para armazenar o total de cliques

  // Checa se o usuário está autenticado e carrega os dados do localStorage
  useEffect(() => {
    const checkAuth = async () => {
      const auth = isAuthenticated();
      if (auth) {
        const storedName = localStorage.getItem('userName');
        const storedID = localStorage.getItem('userID');
        if (storedName && storedID) {
          setIsLoggedIn(true);
          setUserName(storedName);
          setUserID(storedID);

          // Buscar as estatísticas de links e cliques
          const stats = await getUserStats(storedID);
          setUserLinks(stats.links);
          setUserClicks(stats.clicks);
        }
      }
    };
    checkAuth();
  }, []);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);

    // Após fechar o modal, tenta recuperar as informações do usuário do localStorage
    const storedName = localStorage.getItem('userName');
    const storedID = localStorage.getItem('userID');
    if (storedName && storedID) {
      setUserName(storedName);
      setUserID(storedID);
      setIsLoggedIn(true);
    }
  };

  return (
    <header style={styles.header}>
      <div style={styles.titleContainer}>
        <img src={tradeFairIcon} alt="Trade Fair" style={styles.tradeFairIcon} />
        <h1 style={styles.title}>Click Fair</h1>
      </div>
      <div style={styles.stats}>
        <div style={styles.statGroup}>
          <span style={styles.statLabel}>My Links</span>
          <div style={styles.statItem}>
            <img src={myNetworkIcon} alt="My Links" style={styles.iconLeft} />
            <span style={styles.value}>{userLinks}</span> {/* Mostra o total de links */}
          </div>
        </div>
        <div style={styles.statGroup}>
          <span style={styles.statLabel}>My Clicks</span>
          <div style={styles.statItem}>
            <img src={myClicksIcon} alt="My Clicks" style={styles.iconLeft} />
            <span style={styles.value}>{userClicks}</span> {/* Mostra o total de cliques */}
          </div>
        </div>
        <div style={styles.additionalGroup}>
          <div style={styles.iconContainer}>
            <div style={styles.iconWrapper}>
              <img
                src={profileIcon}
                alt="Profile"
                style={styles.icon}
                onClick={openLoginModal} // Abre o modal de login ao clicar no ícone de profile
              />
            </div>
          </div>
        </div>
      </div>
      {isLoggedIn && (
        <div style={styles.userInfo}>
          <span>User: </span>
          <span>{userName}</span>
        </div>
      )}
      <LoginModal isOpen={loginModalOpen} onRequestClose={closeLoginModal} />
    </header>
  );
};

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#1a1e5d',
    color: 'white',
    marginBottom: '20px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    position: 'relative',
  },
  tradeFairIcon: {
    width: '50px',
    height: '50px',
    marginRight: '10px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    background: 'linear-gradient(45deg, #FF6F61, #FFD700)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  stats: {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    position: 'relative',
  },
  statGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  statLabel: {
    marginBottom: '5px',
    fontSize: '14px',
    color: '#FFD700',
    fontWeight: 'bold',
  },
  statItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(10px)',
    padding: '5px 15px',
    borderRadius: '20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    fontSize: '18px',
    color: 'white',
    fontWeight: 'bold',
    width: '90px',
  },
  iconLeft: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  value: {
    textAlign: 'left',
  },
  additionalGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  iconContainer: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
  iconWrapper: {
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '5px',
  },
  icon: {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
  userInfo: {
    marginTop: '10px',
    fontSize: '14px',
    color: '#FFD700', // Texto em amarelo dourado
  },
};

export default Header;
